<template>
  <div>
    <Spinner v-if="loading" />
    <div class="container mt-2" v-else>
      <div class="row">
        <div class="col-md-12">
          <cover-page-settings :item="item" @update="triggerUpdate" />
        </div>
      </div>
      <div class="row mt-sm-3 mt-2">
        <div class="col-md-12">
          <iframe :src="previewUrl" ref="iframe" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/browser.css";
import { mapGetters } from "vuex";
import Spinner from "./Spinner.vue";
import CoverPageSettings from "./CoverPageSettings";

export default {
  components: { Spinner, CoverPageSettings },
  data() {
    return {
      loading: true,
      save_changes: false,
      item: {},
    };
  },
  computed: {
    ...mapGetters(["getDefaults"]),
    previewUrl() {
      return `/wp-admin/admin-ajax.php?action=vue_preview&preview=1&id=${this.item.ID}&design=${this.design}`;
    },
  },
  methods: {
    isVisible(elm) {
      return this.showElements.includes(elm);
    },
    triggerSave() {
      this.save_changes = true;
      this.$store.dispatch("saveDefaultFonts", [
        this.currentFont,
        this.currentSize,
        this.lineHeight,
        ({ message }) => {
          this.save_changes = false;
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
    triggerUpdate() {
      this.$refs.iframe.contentWindow.location.reload();
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      ["cover_page_id"],
      ({ cover_page_id }) => {
        this.$store.dispatch("getPageById", [
          cover_page_id,
          (data) => {
            this.item = data;
            this.loading = false;
          },
        ]);
      },
    ]);
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 1100px;
  display: block;
  border: 1px solid #000;
  padding-top: 20px;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&family=Cinzel:wght@400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Architects+Daughter&family=Shadows+Into+Light&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
</style>